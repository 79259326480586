.container-person-card{
    display: flex;
    flex-wrap: wrap;
    padding: 0;
}

.container-person-card li{
    list-style: none;
    flex: 0 0 100%;
    height: auto;
}

.header-modal-cadastro{
    height: 120px;
    width: 100%;
    background: url('../images/headerbackground.jpg');
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: 0 -180px;
    display: flex;
    align-items: center;
    padding: 0 20px !important;
}

.content-modal-cadastro{
    padding-top: 20px;
    height: calc(100vh - 120px);
    width: 100%;
    overflow: auto;
}

@media screen and (min-device-width: 1024px){
    .person-card{
        margin-bottom: 24px !important;
    }
    .container-person-card li{
        flex: 0 0 33.333333%;
    }
}